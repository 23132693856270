import dom from '../geci/geci.dom';
import UtilsAnalytics from 'CommonUtils/analytics';

const carousel_interval_ms = 6000;

function initCarousels() {
  if (window) {
    var carouselList = dom.allByClass('artwork carousel');
    var carrouselMultiLayerList = dom.allByClass('carousel-container-artwork');

    carouselList.forEach( (carousel) => new Carousel(carousel));
    carrouselMultiLayerList.forEach( (carousel) => new CarrouselMultilayer(carousel));
  }
}

class Carousel {
  constructor(carousel) {
    this.selectedSlideIndex = 0;
    this.startX = 0;
    this.startY = 0;
    this.carousel = carousel;
    this.initCarousel(this.carousel);
  }

  initCarousel(carousel) {
    this.hookDots(carousel);
    this.hookTouchEvents(carousel);
    this.autoPlayCarousel(carousel);
  }

  hookDots(carousel) {
    const dots_wrapper = dom.allByClass('dot-box', carousel)[0];
    const dots = dom.allByClass('dot', dots_wrapper);
    if (dots) {
      dots.forEach((dot, index) => {
        dot.addEventListener('click', (event) => {
          event.preventDefault();
          this.selectedSlideIndex = index;
          this.setCurrentDotAndSlide(carousel);
        });
      });
    }
  }

  hookTouchEvents(carousel) {
    var carouselSlideContainer = dom.allByClass('carousel-container', carousel)[0];
    if (carouselSlideContainer) {
      this.hookTouchStartEvent(carouselSlideContainer);
      this.hookTouchEndEvent(carousel, carouselSlideContainer);
    }
  }

  hookTouchStartEvent(carouselSlideContainer) {
    carouselSlideContainer.removeEventListener('touchstart', this.touchStartEvent.bind(this));
    carouselSlideContainer.addEventListener('touchstart', this.touchStartEvent.bind(this));
  }

  touchStartEvent(event) {
    const touchObject = event.changedTouches[0];
    this.startX = touchObject.pageX;
    this.startY = touchObject.screenY;
  }

  hookTouchEndEvent(carousel, carouselSlideContainer) {
    carouselSlideContainer.removeEventListener('touchend', (event) => {
      this.touchEndEvent(carousel, event);
    });
    carouselSlideContainer.addEventListener('touchend', (event) => {
      this.touchEndEvent(carousel, event);
    });
  }

  touchEndEvent(carousel, event) {
    const dots_wrapper = dom.allByClass('dot-box', carousel)[0];
    const dots = dom.allByClass('dot', dots_wrapper);
    const slidesNumber = dots.length;
    const touchObject = event.changedTouches[0];
    const distanceHorizontal = touchObject.pageX - this.startX;
    const distanceVertical = touchObject.screenY - this.startY;
    if (distanceVertical < 150 && distanceVertical > -150) {
      if (distanceHorizontal < -100 && this.selectedSlideIndex < slidesNumber - 1) {
        this.selectedSlideIndex += 1;
        this.setCurrentDotAndSlide(carousel);
      }
      if (distanceHorizontal > 100 && this.selectedSlideIndex > 0) {
        this.selectedSlideIndex -= 1;
        this.setCurrentDotAndSlide(carousel);
      }
    }
  }

  setCurrentDotAndSlide(carousel) {
    this.showSelectedSlide(carousel);
    this.setCurrentDot(carousel);
  }

  setCurrentDot(carousel) {
    var dots_wrapper = dom.allByClass('dot-box', carousel)[0];
    var dots = dom.allByClass('dot', dots_wrapper);
    dots.forEach( (dot, index) => {
      if (this.selectedSlideIndex === index) {
        dot.classList.add('selected');
        dot.classList.remove('unselected');
      } else {
        dot.classList.remove('selected');
        dot.classList.add('unselected');
      }
    });
  }

  showSelectedSlide(carousel) {
    var slides = dom.allByClass('carousel-container-slide', carousel);
    slides.forEach((slide, index) => {
      if (index === this.selectedSlideIndex) {
        slide.classList.remove('hide');
        slide.classList.remove('fade-out');
        slide.classList.add('show');
        slide.classList.add('fade-in');
      } else {
        slide.classList.remove('show');
        slide.classList.remove('fade-in');
        slide.classList.add('hide');
        slide.classList.add('fade-out');
      }
    });
  }

  getSetIntervalCarouselAutoPlay(carousel, seconds = 10) {
    const dots = dom.allByClass('dot', carousel);
    if (dots?.length) {
      return setInterval(() => {
        const newIndex = this.selectedSlideIndex + 1;
        this.selectedSlideIndex = newIndex < dots.length ? newIndex : 0;
        this.setCurrentDotAndSlide(carousel);
      }, seconds * 1000);
    }
  }

  autoPlayCarousel(carousel) {
    let carouselInterval = this.getSetIntervalCarouselAutoPlay(carousel);
    carousel.addEventListener('mouseover', () => {
      clearInterval(carouselInterval);
    });
    carousel.addEventListener('mouseout', () => {
      carouselInterval = this.getSetIntervalCarouselAutoPlay(carousel);
    });
  }
}
class CarrouselMultilayer {
  constructor(carousel) {
    this.carousel       = carousel;
    this.ui             = {};
    this.slideInterval  = false;
    this.isSliding      = false;    
    this.timer          = null;
    this.overing        = false;
    this.initCarousel(this.carousel);
  }

  initCarousel = (carousel) => {
    this.ui.slides_wrapper  = dom.allByClass('carousel-artworks-wrapper', carousel)[0];
    this.ui.arrow_left      = dom.allByClass('arrow-container-left', carousel)[0];
    this.ui.arrow_right     = dom.allByClass('arrow-container-right', carousel)[0];
    this.ui.progress_bar    = dom.allByClass('progress', carousel)[0];
    this.ui.slides          = Array.from(dom.allByClass('artwork-multilayer', this.ui.slides_wrapper));     
    this.ui.scroll_width    = this.ui.slides[0].getBoundingClientRect().width;
    this.ui.automatic       = carousel.classList.contains('carousel-container-artwork-auto');
    this.ui.slidesLength    = this.ui.slides.length;
    this.ui.slideSize       = this.ui.slides[0].offsetWidth;
    this.resetCarousel();        
    this.hookArrows();
    this.hookKeyDown();
    this.widthChangeChecker();
    this.carousel.addEventListener('mousemove', () => {      
      this.overing = true;          
    });
    this.ui.slides_wrapper.addEventListener('scroll', this.hookScrollEvents.bind(this));  

    this.carousel.addEventListener('mouseout', () => {
      this.overing = false;      
      this.ui.slides_wrapper.removeEventListener('scroll', this.hookScrollEvents.bind(this));      
    });    
    
    if (window.matchMedia('screen and (max-width: 768px)').matches){      
      this.hookTouchEvents();      
    }
            
    if(this.ui.automatic) this.hookAutomaticTransition();
  }
  resetCarousel(sendAnalytics){
    this.ui.slides_wrapper.scroll({left: 0,behavior: 'smooth'});
    this.ui.rec         = this.ui.slides_wrapper.getBoundingClientRect();
    this.isSliding      = false;
    this.timer          = null;
    this.ui.scroll_ini  = 0;
    this.ui.index       = 0;    
    this.ui.scroll_left = 0;

    let totalWidth    = 0;
    const wrapperWidth  = Math.round(this.ui.rec.width);
    for (let i = 0; i < this.ui.slides.length; i++) {
      const slideRec = this.ui.slides[i].getBoundingClientRect();
      totalWidth  += slideRec.width;   
      if(Math.round(totalWidth) === wrapperWidth) {
        this.ui.last = i;                 
        break;
      }   
      if(Math.round(totalWidth) > wrapperWidth) {
        this.ui.last = i-1;                 
        break;
      }
    }     
    this.ui.slider_gap = this.ui.last;
    this.controlButtons(0);
    this.ui.slider_bar = dom.allByClass('_slider_bar', this.ui.progress_bar)[0];
    this.ui.slider_width = 100/(this.ui.slidesLength - this.ui.last);
    this.ui.slider_bar.style.width  = `${this.ui.slider_width}%`;
    this.ui.slider_bar.style.left   = '0%';
    if(sendAnalytics) this.sendAnalytics( this.ui.slides[0] );
  }

  hookArrows(){
    this.ui.arrow_right.addEventListener('click', (event) => {             
      this.arrowsEvents(event,1);
    });
    this.ui.arrow_left.addEventListener('click', (event) => {
      this.arrowsEvents(event,-1);
    });
  }

  hookKeyDown(){
    const _this   = this;
    const visible = function(wrapper) {
      const { innerHeight, innerWidth }   = window;
      const { top, left, bottom, right }  = wrapper.getBoundingClientRect();

      return ((top > 0 && top < innerHeight) ||
      (bottom > 0 && bottom < innerHeight)) &&
      ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth));
    }
    document.addEventListener('keydown', function(event) {      
      const isVisible = visible(_this.ui.slides_wrapper);
      if(!isVisible) return;
      if( event.key === 'ArrowRight' || event.keyCode === 39) {
        _this.arrowsEvents(event,1);
      }
      if( event.key === 'ArrowLeft' || event.keyCode === 37) {
        _this.arrowsEvents(event,-1);
      }
    }); 
  }

  widthChangeChecker(){   
    var width = this.ui.slides_wrapper.scrollWidth;
    setInterval(() => {
      const newWidth = this.ui.slides_wrapper.scrollWidth;
      if (newWidth !== width) {
        width = newWidth;
        this.resetCarousel();
      }
    }, 500);
  }

  hookTouchEvents() {    
    this.hookTouchStartEvent(this.ui.slides_wrapper);      
  }

  hookScrollEvents(event){
    const _this = this;
    window.clearTimeout(this.timer);    
    this.isSliding = true;
    this.checkBar();     
    this.timer = setTimeout(function () {   
      const scroll = event.target.scrollLeft;      
      _this.checkIndex(scroll);       
    }, 500);    
  }

  hookTouchStartEvent(carouselSlideContainer) {
    carouselSlideContainer.removeEventListener('touchstart', this.touchStartEvent.bind(this));
    carouselSlideContainer.addEventListener('touchstart', this.touchStartEvent.bind(this));
  }

  hookAutomaticTransition() {    
    this.startAutomaticTransition();
  }

  arrowsEvents(event, direction) {
    event.preventDefault();
    if (this.isSliding) return;    
    this.overing = false; 
    this.arrowing   
    this.showSlide(direction);
  }

  touchStartEvent() {     
    this.ui.slides_wrapper.classList.add('shifting');
  }

  startAutomaticTransition = () => {
    this.stopAutomaticTransition();
    this.slideInterval = setInterval(() => {
      if (!this.isSliding && !this.overing) this.showSlide(1);
    }, carousel_interval_ms);
  }

  stopAutomaticTransition = () => {
    clearInterval(this.slideInterval);
  }

  showSlide(direction) {    
    this.isSliding = true;
    if(this.ui.last === this.ui.slidesLength - 1 && direction === 1) {
      if(this.ui.automatic) 
        return this.resetCarousel(true);              
      else 
      {
        this.isSliding = false;
        return this.controlButtons(this.ui.index);
      }
        
    }
    if(this.ui.index === 0 && direction === -1) {
      this.isSliding = false;
      return this.controlButtons(this.ui.index);    
    }
    const newIndex = this.ui.index + direction;
    const newLeft  = (newIndex * this.ui.slider_width);
    this.setSlideData(newIndex, direction, false);    
    const send_analytics  = (direction === 1) ? this.ui.slides[this.ui.last]: this.ui.slides[this.ui.index];
    
    this.ui.slides_wrapper.scroll({
      left: this.ui.scroll_left, 
      behavior: 'smooth'
    });    
    this.ui.slider_bar.style.left = `${newLeft}%`;
    this.controlButtons(newIndex); 
    this.isSliding = false;
    if(send_analytics && this.ui.send_analytics) {
      this.sendAnalytics( send_analytics );      
    }         
  };

  checkIndex(scroll) {    
    const direction     = (scroll > this.ui.scroll_ini) ? 1 : -1;
    this.ui.scroll_ini  = scroll;    
    this.isSliding      = false;    
    const showing         = [];
    for (let index = this.ui.index; index <= this.ui.last; index++) {
      showing.push(index);      
    } 
    this.ui.index       = Math.round((this.ui.slides_wrapper.scrollLeft / this.ui.slides_wrapper.scrollWidth) * this.ui.slidesLength);
    this.ui.slides_wrapper.classList.remove('shifting');
    this.setSlideData(this.ui.index, direction, true);
    this.controlButtons(this.ui.index);    
    const newLeft                 = (this.ui.index * this.ui.slider_width);
    this.ui.slider_bar.style.left = `${newLeft}%`;
    if(this.arrowing) this.arrowing = false;
    else if(this.ui.send_analytics && !this.ui.automatic){
      const newShowing = [];   
      const _this = this;   
      for (let index = this.ui.index; index <= this.ui.last; index++) {
        newShowing.push(index);      
      }
      const send_analytics  = [];
      newShowing.forEach( function( el ) {
        if(!showing.includes( el )) send_analytics.push(_this.ui.slides[el]);
      } );
      if(send_analytics?.length) this.sendAnalytics( send_analytics );
    }    
  }

  checkBar() {           
    const index     = Math.round((this.ui.slides_wrapper.scrollLeft / this.ui.slides_wrapper.scrollWidth) * this.ui.slidesLength);    
    const newLeft   = (index * this.ui.slider_width);
    this.ui.slider_bar.style.left = `${newLeft}%`;    
  }

  controlButtons(index) {        
    var hideNext    = (index === this.ui.slidesLength - 1 || this.ui.last === this.ui.slidesLength - 1) ? true : false;
    var hidePrev    = (index === 0 ) ? true : false;   
    
    if(hideNext) this.ui.arrow_right.classList.add('hidden', 'disabled');
    else this.ui.arrow_right.classList.remove('hidden', 'disabled');
    
    if(hidePrev) this.ui.arrow_left.classList.add('hidden', 'disabled');
    else this.ui.arrow_left.classList.remove('hidden', 'disabled');
  }

  sendAnalytics = (visibleSlides) => {
    UtilsAnalytics.sendCarouselPromotionPush(visibleSlides);
  }

  setSlideData(index, direction, isScroll) { 
    this.ui.index           = index;
    this.ui.last            = this.ui.index + this.ui.slider_gap;     
    this.ui.scroll_width    = (this.ui.slides[index].getBoundingClientRect().width) * direction;
    var newPosition         = (isScroll) ? Math.abs(this.ui.scroll_width * index) : this.ui.scroll_left + this.ui.scroll_width;                         
    this.ui.scroll_left     = newPosition;    
    this.ui.send_analytics  = true;            
  }
}

export default initCarousels;