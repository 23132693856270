module.exports = {
  toArray: function(iterable) {
    if (!iterable) return [];
    if ('toArray' in Object(iterable)) return iterable.toArray();
    var length = iterable.length || 0, results = new Array(length);
    while (length--) results[length] = iterable[length];
    return results;
  },

  allByTag: function (tag, scope) {
    return this.toArray((scope || document).getElementsByTagName(tag));
  }, 

  allByClass: function (className, scope) {
    var m = 'getElementsByClassName';
    var self = this;
    if ((scope || document)[m])
      return this.toArray((scope || document)[m](className));
    else {
      var list = [];
      var nodes = this.allByTag('*', scope || document);
      nodes.forEach(function (el) {
        if (self.hasClass(el, className)) list.push(el);
      });
      return this.toArray(list);
    }
  }, 
  firstByClass: function(s, className) {
    return this.allByClass(className, s)[0];
  }, 
  getData: function(element, subtype) {
    var data;
    var regexp = new RegExp('data' + ((subtype)? '-' + subtype + '-(.*)' : '-(.*)'));
    if (element){
      this.toArray(element.attributes).forEach(function(attr) {
        var e = regexp.exec(attr.name);
        if (e) {
          data = data || {};
          data[e[1]] = attr.value;
        }
      });
    }
    return data || false;
  },
};
